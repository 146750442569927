import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/lambo.png";
import IMG2 from "../../assets/kulturen_seminar.jpeg";
import IMG3 from "../../assets/meee.jpg";
import IMG10 from "../../assets/vuitton.jpeg";
import SHINE from "../../assets/shine.jpeg";
import IMG4 from "../../assets/drawling0.jpg";
import IMG5 from "../../assets/drawling1.jpg";
import IMG6 from "../../assets/drawling2.jpg";
import IMG7 from "../../assets/drawling3.jpg";
import IMG8 from "../../assets/drawling4.jpg";
import IMG9 from "../../assets/drawling5.jpg";
import IMG11 from "../../assets/Alpha.jpg";
import IMG12 from "../../assets/Warrior.jpg";
import IMG13 from "../../assets/Werewolf.jpg"

const projects = [
  { id: 5, image: SHINE, title: "shine" },
  { id: 4, image: IMG10, title: "vuitton" },
  { id: 1, image: IMG1, title: "lambo" },
  { id: 2, image: IMG2, title: "kulturen seminar" },
  { id: 3, image: IMG3, title: "hulk" },
  { id: 7, image: IMG11, title: "Alpha" },
  { id: 7, image: IMG12, title: "Warrior" },
  { id: 7, image: IMG13, title: "Werewolf" },
];

const drawling = [
  { id: 5, image: IMG8, title: "drawling4" },
  { id: 2, image: IMG5, title: "drawling1" },
  { id: 3, image: IMG6, title: "drawling2" },
  { id: 4, image: IMG7, title: "drawling3" },
  { id: 1, image: IMG4, title: "drawling0" },
  { id: 6, image: IMG9, title: "drawling5" },
];

const Portfolio = () => {
  return (
    <div>
      <section id="projects">
        <h5>My Interests and Work</h5>
        <h2>Projects</h2>

        <div className="container portfolio__container">
          {projects.map(({ id, image, title }) => (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
            </article>
          ))}
        </div>
      </section>

      <section id="drawling">
        <h2>Drawling</h2>

        <div className="container portfolio__container">
          {drawling.map(({ id, image, title }) => (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
            </article>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Portfolio;
